import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import {
  Container,
  StyledForm,
  StyledFormControl,
  TextBox,
  SendButton,
  FormBox,
  BottomBox,
} from "../components/styles";
import { User } from "../components/types";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import liff from "@line/liff";
import { useAPI } from "../service/api";
import useUserStore from "../store/userStore";
import useAuthStore from "../store/authStore";
import { toast } from "react-toastify";

const RegistrationForm: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [name_kana, setFurigana] = useState<string>("");
  type Gender = "male" | "female" | "other";
  const [gender, setGender] = useState<Gender>("male");
  const [birth_month, setBirthMonth] = useState<string>("1月");
  const [phone_number, setPhoneNumber] = useState<string>("");
  const api = useAPI();
  const { setUser } = useUserStore();
  const { setIsAuthenticated } = useAuthStore();

  const navigate = useNavigate();

  const mutation = useMutation<User, Error, User>({
    mutationFn: async (userData) => {
      try {
        const response: User = await api.create("users", userData);
        return response;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          toast.error("認証エラーが発生しました 再度ログインしてください");
          liff.logout();
          liff.login();

          throw new Error("認証エラー");
        } else if (error.response) {
          throw new Error(
            `APIエラー: ${error.response.status} ${error.response.data.detail}`
          );
        } else {
          throw new Error("ネットワークエラー");
        }
      }
    },
    onSuccess: (data: User) => {
      console.log("会員登録成功:", data);
      setIsAuthenticated(true);
      setUser(data);
      navigate("/mypage");
      toast.success("会員登録成功");
    },

    onError: (error: Error) => {
      console.error("会員登録エラー:", error);
      toast.error("登録に失敗しました");
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const userData: User = {
      name,
      name_kana,
      gender,
      birth_month,
      phone_number,
    };

    mutation.mutate(userData);
  };

  return (
    <Container>
      <TextBox>
        登録いただくと会員価格で
        <br />
        お召し上がりいただけます
      </TextBox>
      <FormBox>
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            id="name"
            label="名前"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            id="furigana"
            label="フリガナ"
            variant="outlined"
            value={name_kana}
            onChange={(e) => setFurigana(e.target.value)}
            required
          />
          <StyledFormControl variant="outlined">
            <InputLabel id="gender-label">性別</InputLabel>
            <Select
              labelId="gender-label"
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value as Gender)}
              label="性別"
            >
              <MenuItem value="male">男性</MenuItem>
              <MenuItem value="female">女性</MenuItem>
              <MenuItem value="other">その他</MenuItem>
            </Select>
          </StyledFormControl>
          <StyledFormControl variant="outlined">
            <InputLabel id="birthMonth-label">お誕生月</InputLabel>
            <Select
              labelId="birthMonth-label"
              id="birthMonth"
              value={birth_month}
              onChange={(e) => setBirthMonth(e.target.value)}
              label="お誕生月"
            >
              {Array.from({ length: 12 }, (_, i) => (
                <MenuItem key={i + 1} value={`${i + 1}月`}>
                  {i + 1}月
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <TextField
            id="phoneNumber"
            label="電話番号 (数字のみ)"
            type="number"
            variant="outlined"
            value={phone_number}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />

          <SendButton type="submit" variant="contained" color="primary">
            送信
          </SendButton>
        </StyledForm>
      </FormBox>
      <BottomBox></BottomBox>
    </Container>
  );
};

export default RegistrationForm;
