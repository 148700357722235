import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { User } from "../components/types";

interface UserState {
  user: User | null;
  setUser: (user: User) => void;
}

const useUserStore = create<UserState>()(
  devtools(
    (set) => ({
      user: null,
      setUser: (user: User) => set((state) => ({ user })),
    }),
    { name: "user-store" }
  )
);

export default useUserStore;
