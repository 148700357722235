import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Container,
  TextBox,
  CardItemContainer,
  BottomBox,
  AddButton,
  CustomDialogTitle,
  SubTitleTextBox,
} from "../components/styles";
import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Grid2,
  Typography,
} from "@mui/material";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";
import { useNavigate } from "react-router-dom";
import axios from "../service/AxiosBaseService";
import { useAPI } from "../service/api";
import { StampCard, UserStampCard } from "../components/types";
import { toast } from "react-toastify";

const StampCardList: React.FC = () => {
  const [user_StampCard, setUserStampCard] = useState<UserStampCard[]>([]);
  const [StampCard, setStampCard] = useState<StampCard[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openDialogId, setOpenDialogId] = useState<number | null>(null);
  const [exchangeComplete, setExchangeComplete] = useState<number | null>(null);
  const [showNewCardSection, setShowNewCardSection] = useState(false);
  const api = useAPI();

  const handleClickOpen = (cardId: number) => {
    setOpenDialogId(cardId);
  };

  const handleClose = () => {
    setOpenDialogId(null);
  };

  const fetchUserStampCard = useCallback(async () => {
    try {
      const response: UserStampCard[] = await api.get("user-stamp-card");
      setUserStampCard(response);
    } catch (error: any) {
      console.error("ユーザースタンプカード取得エラー:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchStampCard = useCallback(async () => {
    try {
      const response = await axios.get("/stamp-card");
      setStampCard(response.data);
    } catch (error: any) {
      console.error("スタンプカード取得エラー:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserStampCard();
    fetchStampCard();
  }, [fetchUserStampCard, fetchStampCard]);

  useEffect(() => {
    const allCardsCompleted = user_StampCard.every((card) => card.end_at);
    setShowNewCardSection(allCardsCompleted);
  }, [user_StampCard]);

  const handleExchangeCard = async (cardId: any) => {
    try {
      const response = await axios.post(
        "/user-stamp-card",
        {
          stamp_card: cardId,
        },
        { withCredentials: true }
      );
      handleClose();
      setExchangeComplete(cardId);
      navigate("/stamp");
    } catch (error: any) {
      console.error(
        "スタンプカード交換エラー:",
        error.response?.data?.detail || error.message
      );
      // alert(
      //   `スタンプカード交換に失敗しました: ${
      //     error.response?.data?.detail || "不明なエラー"
      //   }`
      // );
      toast.error("スタンプカード交換に失敗しました");
    }
  };

  const navigate = useNavigate();
  const historyBack = () => {
    navigate("/stamp");
  };

  const getStampCard = (cardId: Number) => {
    navigate("/stamp", {
      state: {
        toast: { status: cardId },
      },
    });
  };

  return (
    <Container>
      <TextBox>スタンプカード一覧</TextBox>
      <Grid2 container spacing={2} sx={{ p: 1, width: "80%" }}>
        {user_StampCard.map((card) => (
          <CardItemContainer
            key={card.id}
            onClick={() => {
              getStampCard(card.id);
            }}
          >
            <CardContent>
              <Typography variant="h6" component="div">
                {card.stamp_card.stamp_card_name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {!card.end_at && card.is_active && "現在のスタンプカード"}
                {card.end_at &&
                  `★  完了日：${dayjs(card.end_at).format("YYYY-MM-DD")}`}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  スタンプ画像：
                </Typography>
                <CardMedia
                  component="img"
                  image={card.stamp_card.stamp_image}
                  alt="Paella dish"
                  sx={{ height: "30px", width: "30px" }}
                />
              </Box>
            </CardContent>
          </CardItemContainer>
        ))}
        {showNewCardSection === true ? (
          <>
            <SubTitleTextBox>
              <Typography variant="h6" component="div">
                新規スタンプカード
              </Typography>
            </SubTitleTextBox>
            {StampCard.map((card) => (
              <Box key={card.id} sx={{ width: "100%" }}>
                <CardItemContainer onClick={() => handleClickOpen(card.id)}>
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {card.stamp_card_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      スタンプ数：{card.max_stamps}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        スタンプ画像：
                      </Typography>
                      <CardMedia
                        component="img"
                        image={card.stamp_image}
                        alt="Paella dish"
                        sx={{ height: "30px", width: "30px" }}
                      />
                    </Box>
                  </CardContent>
                </CardItemContainer>
                <Dialog open={openDialogId === card.id} onClose={handleClose}>
                  <CustomDialogTitle>スタンプカードの設定</CustomDialogTitle>
                  <DialogContent sx={{ margin: "0px 10px" }}>
                    <CardItemContainer>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6" component="div">
                            {card.stamp_card_name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            スタンプ数：{card.max_stamps}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            スタンプ画像：
                          </Typography>
                          <CardMedia
                            component="img"
                            image={card.stamp_image}
                            alt="Paella dish"
                            sx={{ height: "30px", width: "30px" }}
                          />
                        </Box>
                      </CardContent>
                    </CardItemContainer>
                    <Typography>
                      {card.stamp_card_name}を使用しますか？
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                      キャンセル
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleExchangeCard(card.id)}
                      autoFocus
                    >
                      使用
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={exchangeComplete === card.id}
                  onClose={() => setExchangeComplete(null)}
                >
                  <DialogContent sx={{ margin: "0px 20px" }}>
                    <CardItemContainer>
                      <CardContent>
                        <Typography variant="h6" component="div">
                          {card.stamp_card_name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            スタンプ数：{card.max_stamps}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            スタンプ画像：
                          </Typography>
                          <CardMedia
                            component="img"
                            image={card.stamp_image}
                            alt="Paella dish"
                            sx={{ height: "30px", width: "30px" }}
                          />
                        </Box>
                      </CardContent>
                    </CardItemContainer>
                  </DialogContent>
                  <CustomDialogTitle sx={{ marginTop: "0px" }}>
                    {card.stamp_card_name}
                    を使用するスタンプカードに設定しました
                  </CustomDialogTitle>
                  <DialogActions>
                    <Button
                      variant="contained"
                      onClick={() => setExchangeComplete(null)}
                      autoFocus
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            ))}
          </>
        ) : (
          <></>
        )}
      </Grid2>
      {showNewCardSection === true ? (
        <></>
      ) : (
        <AddButton onClick={historyBack}>
          <UTurnLeftIcon
            sx={{ width: "40px", height: "40px", transform: "rotate(90deg)" }}
          />
        </AddButton>
      )}
      <BottomBox></BottomBox>
      <BottomBox></BottomBox>
    </Container>
  );
};

export default StampCardList;
