import React, { createContext, useContext } from "react";
import axios from "./AxiosBaseService";

export type APIContextType = {
  getUser: () => Promise<any>;
  getOne: <T>(resource: string, id: number | string) => Promise<T>;
  get: <T>(resource: string) => Promise<T>;
  create: <T>(resource: string, data: any, hasFile?: boolean) => Promise<T>;
  getWithAction: <T>(
    resource: string,
    action: string,
    data?: any
  ) => Promise<T>;
  postWithAction: <T>(
    resource: string,
    action: string,
    data: any
  ) => Promise<T>;
  update: <T>(resource: string, id: number | string, data?: any) => Promise<T>;
  [method: string]: (...args: any[]) => Promise<any>;
};

const APIContext = createContext<APIContextType | null>(null);

export const useAPI = () => {
  const api = useContext(APIContext);
  if (!api) throw new Error("useAPI must be used within APIProvider");
  return api;
};

export const APIProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const apiContext = React.useMemo(
    () => ({
      getUser: () => axios.get("/users"),
      getOne: async (resource: string, id: number | string) => {
        const response = await axios.get(`${resource}/${id}`);
        return response.data;
      },
      get: async (resource: string) => {
        const response = await axios.get(`${resource}`);
        return response.data;
      },
      create: async (
        resource: string,
        payload: any,
        hasFile: boolean = false
      ) => {
        if (hasFile) {
          const response = await axios.post(`${resource}`, payload, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          return response.data;
        } else {
          const response = await axios.post(`${resource}`, payload);
          return response.data;
        }
      },
      getWithAction: async (
        resource: string,
        action: string,
        payload?: any
      ) => {
        if (payload) {
          const response = await axios.get(`${resource}/${action}`, payload);
          return response.data;
        } else {
          const response = await axios.get(`${resource}/${action}`);
          return response.data;
        }
      },
      postWithAction: async (
        resource: string,
        action: string,
        payload: any
      ) => {
        const response = await axios.post(`${resource}/${action}`, payload);
        return response.data;
      },
      update: async (resource: string, id: number | string, payload?: any) => {
        if (payload) {
          const response = await axios.patch(`${resource}/${id}`, payload);
          return response.data;
        } else {
          const response = await axios.patch(`${resource}/${id}`);
          return response.data;
        }
      },
    }),
    []
  );

  return (
    <APIContext.Provider value={apiContext}>{children}</APIContext.Provider>
  );
};
